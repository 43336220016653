//components
//components
import React from "react"
import { Grid } from "@material-ui/core"
import BulletItem from "../components/bullet-item"
import Article from "../components/article"
import Section from "../components/section"
import HeroSection from "../components/hero-section"
import Testimonial from "../components/testimonial"
import ListCard from "../components/list-card"
import AboutImage from "./assets/Ivone-Foto-4.jpg"
import About from "../components/about"
import Card from "../components/card"

//IMAGES
import HeroImage from "./assets/Ivone-Foto5-flipped.jpg"
import FotoDanielH from "./assets/Foto_DanielH.jpeg"
import FotoGermanM from "./assets/Foto_GermanM.jpeg"
import FotoNataliaA from "./assets/Foto_NataliaA.jpeg"
import FotoIvoneH from "./assets/Foto_IvoneH.jpeg"
import Navigation from "../components/navigation"
import ImageFotoCoahing1 from "./assets/FotoCoahing1.jpeg"
import ImageSesiones from "./assets/MeSesionesIndividuales.jpeg"
import ImageCoaching from "./assets/FotoCoaching.jpeg"


// import ImageDeepocean from "./assets/pexels-emiliano-arano-1350197.jpg"

//ICONS
import { IoIosArrowForward } from "react-icons/io"
import { FaCircle } from "react-icons/fa"
import Footer from "../components/footer"
import { ImCircleRight } from "react-icons/im"
import { BsCheckCircle } from "react-icons/Bs"

export default function SM() {
  const bulletIcon1 = <IoIosArrowForward color="#92baba" size="36px" />
  const bulletIcon2 = <FaCircle size="4px" color="#92baba"/>
  const bulletIcon3 = <BsCheckCircle color="#92baba" size="20px" />
  const bulletIcon4 = <ImCircleRight color="#92baba" size="12px" />

  const Testimonio_Daniel4 = `El coaching de Ivone es un recurso muy necesario para quienes buscan desarrollar 
  técnicas para trabajar con su salud mental y tomar mejores decisiones de vida basadas en sus objetivos personales. 
  Su enfoque empático y eficaz proporciona un rayo de esperanza para quienes luchan contra la inestabilidad emocional 
  o contratiempos profesionales. Recomiendo de todo corazón las sesiones personalizadas con Ivone a cualquiera que busque orientación emocional 
  y mental en cualquier etapa de su vida.`
  const TestName_Daniel4 = "Daniel H, Manager"

  const Testimonio_NataliaA = `Recomiendo totalmente trabajar con Ivone, es estructurada, empática, 
  emocional y sabe cuándo hacer las preguntas correctas. Las respuestas están dentro de ti y ella te ayuda a descubrirlas.
  Me gustaron la “tareas” que me dio, esas preguntas simples pero complicadas que te hacen 
  buscar respuestas en tu interior y te ayudan a ver las cosas desde una perspectiva diferente.
  Es muy importante comprometerse, hacer el trabajo, aceptar el cambio, ser abierta y honesta 
  consigo misma. ¡Trabajar con Ivone fue terapeutico de una manera muy positiva!
  Para alguien que ha vivido la depresión y una crisis de vida, fue un desafío!  
  Muchas cosas estaban justo allí y no las podía ver. Estoy muy agradecida con Ivone por abrirme los ojos 
  y ayudarme en este importante proceso personal! La recomiendo ampliamente!`
  const TestName_NataliaA = "Natalia A, Artista Plástica"

  const Testimonio_German2 = `Considero que lo que más me ayudó fue el tema de autovaloración/autoestima. 
  Al momento de yo mismo valorarme, estoy seguro que tendré mejores herramientas para futuras negociaciones laborales. 
  En el nuestra última sesión hablamos de esa parte psicológica que debo enfrentar y mejorar. 
  Lo curioso es que anteriormente no me lo había cuestionado, no lo había reflexionado y con su ayuda, fue como abrir los ojos.  
  Su estrategia es hacerte preguntas e irte guiando, utilizando con ello herramientas de que permiten el autoanálisis, 
  y que uno mismo vaya reflexionando y con ello ir encontrando el mejor camino basado en lo que uno realmente quiere. 
  Las “tareas” que me propuso, fueron prácticas, interesantes y de mucha ayuda. 
  Ivone me ayudó a iluminar mi camino! ¡Altamente recomendable!`
  const TestName_German2 = "German M, Ingeniero"

  const List_MisEventosEspanol = [
    <span>
      {" "}
      Taller
      <strong>
        {" "}
        "Emociones: el Motor para tu Desarrollo Personal y Profesional en
        Europa."{" "}
      </strong>
      Para el 5to Encuentro de Mujeres Hispano Hablantes en Europa:
      <strong>
        {" "}
        “Desarrollo profesional como mujer migrante en Europa: Reintégrate,
        Renuévate, Reinvéntate”
      </strong>
      , Organizado por el equipo de Mujeres Hispanohablantes en Europa (MHE) con
      la colaboración de la Secretaría de Relaciones Exteriores de México, el
      Instituto de los Mexicanos en el Exterior y la Red global de Mexicanos en
      el extranjero en la <strong>Embajada de México en Berlin</strong>, Octubre
      2022.
    </span>,

    <span>
      {" "}
      Participación en el Panel<strong> "Mujeres que Inspiran" </strong>
      por mi destacada y exitosa trayectoria como mujer migrante en Europa. Para
      el 5to Encuentro de Mujeres Hispano Hablantes en Europa. 'Reintégrate,
      Renuévate, Reinvéntate', en la{" "}
      <strong>Embajada de México en Berlin</strong>, Octubre 2022.
    </span>,

    <span>
      {" "}
      Masterclass
      <strong> "Cómo motivarte para Lograr tus Metas" </strong>
      para el Espacio Internacional de Psicología, Abril 2022.
    </span>,

    <span>
      {" "}
      Conferencia Virtual
      <strong>
        {" "}
        “La Triada de tu Éxito como Mujer: Reconocimiento, Autoestima y
        Confianza”{" "}
      </strong>
      como parte del Congreso Virtual “Mujer por Fuera y por Dentro”, Mayo 2021.
    </span>,

    <span>
      {" "}
      Conferencia Virtual
      <strong> "Entiendo mis Emociones y Atiendo mis Necesidades" </strong>
      para la Organización de Mujeres Hispanohablantes en Alemania, Febrero
      2021.
    </span>,

    <span>
      {" "}
      Conferencia Virtual
      <strong>
        {" "}
        "Cómo Ganarle a la Impostora que Vive en Ti. Diez Estrategias para
        Desarrollarte Personal y Profesionalmente en el Extranjero"{" "}
      </strong>
      como parte del evento virtual Bienestar Emocional y Holístico para la
      Comunidad de Latinas en Berlin, Alemania, Diciembre 2020.
    </span>,

    <span>
      {" "}
      Artículo{" "}
      <strong>
        {" "}
        "Las Palabras que Usamos Impactan Emocionalmente Nuestras Vidas"{" "}
      </strong>
      para la Revista Digital Hispanas Creativas, Diciembre 2020.
    </span>,

    <span>
      {" "}
      Episodio de Podcast
      <strong>
        {" "}
        "Síndrome del Impostor: ¿Porque nos afecta más a los migrantes?"{" "}
      </strong>
      para el Podcast Onda Sana de la Comunidad de Hispanos Migrantes, Diciembre
      2020.
    </span>,
  ]

  const List_MisEventosIngles = [
    <span>
      {" "}
      Workshop
      <strong> "Embrace your value and boost your Confidence" </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Octubre 2020.
    </span>,

    <span>
      {" "}
      Workshop
      <strong> "How to boost your Self-Esteem and Self-Confidence" </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Octubre 2020.
    </span>,

    <span>
      {" "}
      Workshop
      <strong> "Confident ME- Women Only" </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Noviembre 2019.
    </span>,

    <span>
      {" "}
      Workshop
      <strong>
        {" "}
        "Deep conversations about Vulnerability: finding new insights"{" "}
      </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Julio 2019.
    </span>,

    <span>
      {" "}
      Workshop
      <strong>
        {" "}
        "How to Overcome Self-Doubt and become inspired using regular people as
        Role Models"{" "}
      </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Octubre 2018.
    </span>,

    <span>
      {" "}
      Workshop
      <strong>
        {" "}
        "How to stop your Limiting Beliefs and stop them from affecting your
        future"{" "}
      </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Septiembre 2018.
    </span>,

    <span>
      {" "}
      Workshop
      <strong> "Transforming your Dream Life in to Smart Goals" </strong>
      con el grupo de Chicas Superpoderosas Let's Change Our Inner World
      Together en meetup, Agosto 2018.
    </span>,

    <span>
      {" "}
      Workshop
      <strong> "Let's strengthen our Self-awareness" </strong>
      con el grupo de Chicas Superpoderosas Lets Change Our Inner World Together
      en meetup, Agosto 2018.
    </span>,
  ]

  return (
    <>
      <Navigation active={1} />

      <HeroSection
        bgImage={HeroImage}
        id="Home"
        subtitle="Asesoría Psicológica, Gestión Emocional, Coaching de Vida e Inteligencia Intercultural en la Migración."
        overlay="part"
        overlayClassName="white"
        titleClassName="left"
        color="blue1"
        forceAspectRatioOnMobile
        h1="Ivone Herrera"
        overlayColor="rgba(7, 60, 119, 0.65)"
      ></HeroSection>

      <Section color="white">
        <Article
          layout="wide"
          titleClassName="lustria bold blue1 font-size-verylarge lineheight-large"
          title="¿Te suenan familiares algunas de estas ideas?"
        >
          <Grid container spacing={1}>
            <Grid item md={6}>
            <BulletItem icon={bulletIcon1}>
                Sabes que puedes más, porque ya lo has demostrado antes, pero en
                este momento te sientes atorada, te falta claridad y no sabes
                por dónde empezar para cambiar esta situación.
              </BulletItem>
            <BulletItem icon={bulletIcon1}>
            ¿Experimentas dificultades para integrarte en tu nuevo entorno, y sientes que algunas partes de ti 
            se han quedado atrás?

              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Te sientes insatisfecha con la vida que llevas, no las disfrutas
                en plenitud porque no estas haciendo lo que te gustaría.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
              ¿Te sientes abrumada por tus emociones y sin saber cómo navegarlas? o ¿Te sientes perdida?

              </BulletItem>
              
              <BulletItem icon={bulletIcon1}>
              ¿No sabes cómo adaptar tu método de comunicación y comportamientos para alinearte con las nuevas normas 
              culturales? ¿Te cuesta trabajo socializar?
              </BulletItem>
            </Grid>
            <Grid item md={6}>
              <BulletItem icon={bulletIcon1}>
              ¿Te encuentras reviviendo constantemente eventos pasados y sientes que esas experiencias te afectan 
              en el presente, causándote inquietud, ansiedad o miedo sin una causa aparente?

              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                Te falta motivación, te sientes insegura y dudas de ti misma o
                de lo que podrías lograr. Has estado largo tiempo en esta situación y eso a veces te
                provoca ansiedad.
              </BulletItem>
              <BulletItem icon={bulletIcon1}>
                ¿Frente a nuevos desafíos o contratiempos, sientes que te cuesta hacerles frente o encontrarles 
                el lado positivo, y ya sientes el impacto negativo en tus emociones? ¿Sientes que tu capacidad 
                de resolver y avanzar han disminuido?

              </BulletItem>
              <BulletItem icon={bulletIcon1}>
              ¿Experimentas una sensación de vacío o falta de dirección en tu vida? 
              ¿Te preguntas constantemente sobre tu propósito y estas buscando una motivación que dé 
              sentido a tu existencia?

              </BulletItem>
            </Grid>
          </Grid>
        </Article>
      </Section>

      <Section color="turkis-blue1" id="Centered-text">
        <Article className="bold lustria center white font-size-verylarge lineheight-large">
          La buena noticia es que... 
          <br />
          ¡Esta en tus manos transformar esa situación!
          <br />
          ...con la ayuda y el apoyo adecuados 
        </Article>
        
      </Section>


<Section color="beige1">
        <Article layout="wide">
          <About
            titleClassName="lustria bold center blue1 font-size-verylarge lineheight-large"
            title="Te cuento un poco de mi historia"
            image={AboutImage}
          >
            <span className="left lineheight-large">
            En septiembre de 2012 llegué a Alemania para estudiar una Maestría en Simulaciones Computacionales. 
            Dos meses después, debido a los múltiples factores que acompañan el <strong>duelo migratorio,</strong> entre ellos la falta de luz solar,
            ya sufría de <strong>depresión clínica</strong> e incluso estuve al borde del suicidio. 
            <br /><br />
            ¡Tenía una gran oportunidad pero la vida había perdido todo su sentido y todo su color! 
            ¿Qué me había sucedido? 
            <br /><br />
            <strong>La depresión fue la forma en que mi cuerpo y mis emociones me indicaban que era tiempo de re-evaluar mi vida y mis prioridades!</strong>
            <br />
            <br />
            Sobreviví, pero durante años seguí sintiéndome 
            <strong>insatisfecha, estancada,</strong> <strong>con ansiedad constante y con muchas dudas e incertidumbre. </strong>  
            Mi intuición me decía que estaba en mis manos hacer algo para transformar mi situación y ¡Recuperar mi vida!
            <br /><br />
            Entonces recordé que años antes, yo amaba la vida, y era una mujer segura y 
            confiaba de tal manera en mí misma, que era capaz de conseguirlo todo. 
            No conocía imposibles ni excusas y podía lograr todo lo que me propusiera.
            <br /><br />
            <div className=" center blue1  font-size-medium lineheight-large">
            ¡Estaba segura de que sí alguna fue así, podía volver a serlo! </div>          
            <br />
            
            <div className="center blue1  font-size-medium lineheight-large">
            ¿Qué elementos hacían la diferencia entre la sensación de poderlo todo y la desesperación total? 
            ¿Cuál era el secreto?
            </div>
            <br />
            Después de recuperarme y reconstruirme nuevamente en una mujer confiada de mi misma, 
            te puedo decir con certeza que mis <strong>emociones</strong> jugaron un papel fundamental para traerme de nuevo a la vida. 
            Me ayudaron a <strong>re-conectar conmigo misma, con lo que disfruto y lo que da sentido a mi vida. </strong> 
            Y fueron mis emociones, no mi mente, las que me indicaron el camino de vuelta. 
            <br /><br />
            También utilicé los recursos de la <strong>Psicología Positiva</strong> <i>-¿Cuáles son mis fortalezas? y 
            ¿Cómo puedo utilizarlas para sentirme mejor?-</i> y con <strong>Entrenamiento Intercultural</strong> me di cuenta de cómo 
            las diferencias culturales estaban impactando fuertemente mis emociones y habilidades y me estaban limitando.  
            <br /><br />
            <div className=" center blue1  font-size-medium lineheight-large">
            ¡Tuve que re-descubrirme, <br /> expandir mi identidad y superar mis bloqueos emocionales!</div>
            <br />
            Por todo esto, decidí formarme primero como <strong>Life Coach</strong> y luego como 
            <strong> Especialista en Psicoterapia Humanista Gestalt</strong>, para poder enseñarte, con fundamentos, lo que yo ya he vivido y experimentado. 
            <br /><br />
            Lo que te transmito no lo sé solo “en teoría”, 
            yo también he experimentado todas <strong>las emociones angustiantes del sentirse perdida y sin sentido, 
            el miedo paralizante y la ansiedad “inexplicables” al intentar hablar alemán, la desconexión, 
            la falta de motivación, y luego las dudas, la incertidumbre e incluso el miedo que acompañan a una 
            Reinvención Profesional en un nuevo país. </strong>
            <br /><br />
            </span>
          </About>
        </Article>
      </Section>

      {/* <Section color="white" id="Centered-text">
        <Article className="bold lustria center turkis-blue1 font-size-verylarge lineheight-large">
          Y es gracias a todo lo que he vivido en este camino y a mí formación Profesional 
              que estoy preparada y lista para compartirlo contigo. 
              <br />¡Para que tú también puedas aplicarlo a tu vida en el extranjero!
        </Article>
      </Section> */}


      <Section color="white">
        <Article layout="narrow" 
        className="bold lustria center turkis-blue1 font-size-verylarge lineheight-large">
          <Testimonial name="" variation="" image={FotoIvoneH}>
          Y es gracias a todo lo que he vivido en este camino y a mí formación Profesional 
              que estoy preparada y lista para compartirlo contigo. 
              <br />¡Para que tú también puedas aplicarlo a tu vida en el extranjero!
          </Testimonial>

        </Article>
      </Section>


      <Section
        color="blue1"
        id="formation"
        // bgImage={ImageDeepocean}
        // overlay="full"
        // overlayColor="rgba(250, 246, 246,.6)"
        // overlayColor="rgba(38, 159, 178, .6)"
      >
        <Article
          // className="playfair font-size-verylarge"
          // color="turkis-blue1"
          // bgImage={ImageTurkisWay}
          // // overlay="full"
          // overlayColor="rgba(38, 159, 178, 0)"

          layout="narrow"
          // titleClassName="lustria bold turkis-blue1 font-size-verylarge lineheight-large"
          titleClassName=" lustria white bold font-size-extralarge lineheight-verylarge"
          title={<>
            <span>Así es como me he preparado para poder apoyarte mejor</span>
            <br />
            <span className="font-size-large">Mi experiencia y formación</span>
            </>}
        >
          {/* <Grid container spacing={1}> */}
          <Grid item md={12}>
            <Card className="mb-small">
            <div className="bold font-size-medium "><BulletItem>FORMACIÓN</BulletItem></div>
            <BulletItem icon={bulletIcon2}>Especialista en Psicoterapia Gestalt </BulletItem>
            <BulletItem icon={bulletIcon2}>Especialista en Psicoterapia Humanista </BulletItem>
            <BulletItem icon={bulletIcon2}>Especialidad en Traumas y Estrés Crónico </BulletItem>
            <BulletItem icon={bulletIcon2}>Especialidad en Duelo </BulletItem>
            <BulletItem><strong><i>Instituto Humanista de Psicoterapia Gestalt, México</i></strong></BulletItem>
            <br />

            <BulletItem icon={bulletIcon2}>Especialista en Psicología Positiva </BulletItem>
            <BulletItem icon={bulletIcon2}>Life Coach | Coach de Vida </BulletItem>
            <BulletItem><strong><i>Academy of Modern Applied Psychology</i></strong></BulletItem>
            <br />

            <div className="bold font-size-medium"><BulletItem>CURSOS</BulletItem></div>
            <BulletItem icon={bulletIcon2}>Gestión del Estrés Crónico </BulletItem>
            <BulletItem icon={bulletIcon2}>Intervención en Crisis </BulletItem>
            <BulletItem icon={bulletIcon2}>Intervenciones Gestálticas para Crisis Sociales Actuales   </BulletItem>
            <BulletItem icon={bulletIcon2}>Trabajo de Sueños en el Enfoque Gestalt </BulletItem>
            <BulletItem icon={bulletIcon2}>La Experiencia de la Ansiedad </BulletItem>
            <BulletItem icon={bulletIcon2}>Funcionalidad y Disfuncionalidad en el Enfoque Gestalt</BulletItem>
            <BulletItem icon={bulletIcon2}>Trabajo con Sentimientos y Emociones </BulletItem>
            <BulletItem><strong><i>Instituto Humanista de Psicoterapia Gestalt, México</i></strong></BulletItem>
            <br />
            <BulletItem icon={bulletIcon2}>Identificación y Desarrollo de Fortalezas</BulletItem>
            <BulletItem><strong><i>Academy of Modern Applied Psychology</i></strong></BulletItem>
            
            <br />
            <BulletItem icon={bulletIcon2}>M.Sc. Computer Simulations  </BulletItem>
            <BulletItem><strong><i>Technical University of Berlin & Royal Institute of Technology Stockholm</i></strong></BulletItem>
            <br />
            <BulletItem icon={bulletIcon2}>B.Sc. Física </BulletItem>
            <BulletItem><strong><i>Universidad Nacional Autónoma de México, México</i></strong></BulletItem>


            </Card>
            {/* </Grid> */}
          </Grid>
          {/* <ListCard text={List_Incluye} listItemIcon={bulletIcon4}> */}
        </Article>
      </Section>

      <Section color="turkis-blue1" id="Centered-text">
        <Article className="lustria bold center font-size-verylarge white lineheight-large">
          ¡Es posible re-conectar contigo mism@, recuperar tu confianza y tu seguridad!
          <br />
          ¡Estoy aquí para ayudarte a lograrlo!
        </Article>
        
      </Section>


      <Section color="white" id="Centered-text">
        <Article className="center">
          <ListCard
            title="Eventos en los que he sido Ponente"
            titleClassName="lustria bold blue1 center font-size-verylarge lineheight-extralarge"
            text={List_MisEventosEspanol}
            listItemIcon={bulletIcon4}
          ></ListCard>
        </Article>
      
        <Article className="center">
          <ListCard
            title="Eventos en Inglés"
            titleClassName="lustria bold blue1 center font-size-verylarge lineheight-extralarge"
            text={List_MisEventosIngles}
            listItemIcon={bulletIcon4}
          ></ListCard>
        </Article>
      </Section>
      
      <Section color="white">
        <Article layout="narrow" 
                  titleClassName="lustria bold blue1 font-size-verylarge  lineheight-large"
        title="Ell@s ya saben lo que es trabajar conmigo">
          <Testimonial name={TestName_NataliaA} variation="small" image={FotoNataliaA}>
          {" "}
              {Testimonio_NataliaA}
          </Testimonial>
          <Testimonial name={TestName_Daniel4} variation="small" image={FotoDanielH}>
          {" "}
              {Testimonio_Daniel4}
          </Testimonial>
          <Testimonial name={TestName_German2}  variation="small" image={FotoGermanM}>
          {" "}
              {Testimonio_German2}
          </Testimonial>
          
        </Article>
      </Section>


      <Section color="beige1" id="mis-servicios">
        <Article
          layout="wide"
          titleClassName="blue1 lustria font-size-verylarge lineheight-large"
          title={<>
            <span>DESCUBRE CÓMO PODEMOS TRABAJAR JUNT@S</span>
            <br />
            <span className="font-size-large">Mis Servicios</span>
            </>}
        >
          <Grid container spacing={3} justify="center">
            <Grid item md={4} xs={10}>
              <Card
                //link="/proximamente"
                avatar={ImageSesiones}
                titleClassName="lustria font-size-medium center"
                className="left"
                title={
                  <span>
                    Asesoría Psicológica Individual
                  </span>
                }
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Exploramos distintas áreas de tu vida y la forma en que diversas experiencias de tu historia 
                personal influyen en tu presente. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Empleamos las técnicas de la psicología para explorar y 
                comprender mejor tus emociones, identificar tus desafíos personales. Te brindo estrategias para 
                superarlos.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Es un proceso altamente personalizado y de acuerdo a tus necesidades que comprende 
                la mente, las emociones y el cuerpo.
                  {" "}
                </BulletItem>
                
                <div className="center  ">
                Sesiones de 60 min. <br /> Adultos, mujeres y hombres. 
                </div>

              </Card>
            </Grid>
            <Grid item md={4} xs={10}>
            <Card
                //link="/curso-amplifica-tu-confianza"
                avatar={ImageCoaching}
                titleClassName="lustria font-size-medium center"
                className="left"
                title={
                  <span>
                    Life Coaching | Coaching de Vida
                  </span>
                }
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Te apoyo para que alcances tus metas e incrementes tu bienestar. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Juntas exploraremos tus fortalezas, deseos y desafíos personales. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Te proporcionaré herramientas para fortalecer tu resiliencia y
                lograr un equilibrio más pleno en tu vida. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Te ayudo a definir con claridad tus metas y el plan 
                que necesitas para alcanzarlas.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  ¡Transformaras tu vida de manera positiva!
                  {" "}
                </BulletItem>

                <div className="center  ">
                Sesiones de 60 min. <br /> Adultos, mujeres y hombres. 
                </div>  
              </Card>
            </Grid>

            <Grid item md={4} xs={10}>
              <Card
                //link="/curso-amplifica-tu-confianza"
                avatar={ImageFotoCoahing1}
                titleClassName="lustria font-size-medium center"
                className="left"
                title={
                  <span>
                    Programas Personalizados
                  </span>
                }
              >
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Diseño un programa adaptado a tus desafíos y objetivos personales.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Nos enfocamos en desarrollar las habilidades que necesitas para alcanzar tus metas. 
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Desarrollo charlas, cursos y talleres para adultos según necesidades y objetivos específicos.
                  {" "}
                </BulletItem>
                <BulletItem icon={bulletIcon3}>
                  {" "}
                  Si ya tienes un objetivo en mente, consulta conmigo para saber más sobre cómo podemos trabajar juntas.
                  {" "}
                </BulletItem>
                <br /> 
                <div className="center  ">
                Programas individuales y grupales <br /> 
                Duración según objetivos
                </div>

              </Card>
            </Grid>
            
          </Grid>
        </Article>
      </Section>

      <Section color="blue1">
        <Article
          layout="narrow"
          titleClassName="lustria white bold font-size-extralarge lineheight-verylarge"
          title="Si todavía tienes dudas... "
        >
          <Card>
            <BulletItem icon={bulletIcon1}>
              Te ofrezco una <strong> Llamada de Valoración Sin Costo de 20 min </strong> para
              conocer un poco de ti, saber en qué áreas necesitas apoyo e
              <strong> identificar si sientes afinidad y confianza para trabajar conmigo.</strong>
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Durante la llamada aclaro todas tus dudas e inquietudes y
              comentamos de qué modo te puedo apoyar específicamente.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              Por respeto al tiempo de ambas, te pido que seas puntual y en caso
              de no poder asistir a la llamada, te agradeceré me lo hagas saber
              a la brevedad posible.
            </BulletItem>
            <BulletItem icon={bulletIcon1}>
              ¡Escríbeme un mensaje de <a href="https://wa.me/4915906745786?text=Hola!%20Ya%20revisé%20tu%20Web,%20y%20me%20gustaría%20agendar%20una%20Llamada%20de%20Valoración%20contigo." rel="noreferrer" target="_blank">Whatsapp</a> para acordar la fecha y hora de nuestra llamada!
            </BulletItem>
          </Card>
        </Article>
      </Section>

      <Footer />
    </>
  )
}
